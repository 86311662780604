<mat-dialog-content>
  <h1>Créer un nouveau projet</h1>
  <div class="zone-input">
    <mat-form-field appearance="outline">
      <mat-label>Nom du projet</mat-label>
      <input matInput />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Ajouter une personne au projet</mat-label>
      <a class="invite-button" href="#">Invite</a>
      <input matInput />
    </mat-form-field>

    <!-- Importer un projet existant -->
    <div
      class="import-projet"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
      <div class="import-zone" [class.dragging]="isDragging">
        <div class="import-icon">
          <mat-icon class="icon-circle">note_add</mat-icon>
        </div>
        <p>Importer un projet existant</p>
        <span class="import-details">
          Formats : CSV, XLS • Taille Maximale : 100 Go
        </span>
        <a href="javascript:void(0)" class="ajouter-button" (click)="triggerFileInput()">Ajouter</a>
        <input type="file" accept=".xls,.csv" #fileInput (change)="onFileSelected($event)" style="display:none" />
      </div>
    </div>

    <!-- Affichage du fichier sélectionné -->
    <div *ngIf="selectedFile" class="file-selected">
      <div class="file-info">
        <mat-icon>description</mat-icon>
        <span class="file-name">{{ selectedFileName }}</span>
        <button class="delete-button" (click)="onDeleteFile()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Boutons d'action -->
  <div class="action-buttons">
    <button class="cancel-button" href="">Annuler</button>
    <button class="validate-button">Valider</button>
  </div>
</mat-dialog-content>
