
<mat-card class="card-projet" appearance="outlined">
  <button class="option-button" mat-icon-button [matMenuTriggerFor]="menu" >
    <mat-icon>more_vert</mat-icon>
  </button>
  <a href="../../projet/{{ data().id }}/producteurs">
    <mat-card-header>

    <mat-icon class="header-icon">folder</mat-icon>

    <mat-card-title matTooltip="{{ data().libelle }}">{{ truncate(data().libelle, 20) }}</mat-card-title>
      <mat-card-subtitle>Édité par {{data().user.prenom}} {{data().user.nom.slice(0, 1)}} • dateEdition</mat-card-subtitle>

      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <a href="../../projet/{{ data().id }}/producteurs">
          <mat-icon>open_in_new</mat-icon>
          <span>Ouvrir</span>
          </a>
        </button>
        <button mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Renommer</span>
        </button>
        <button mat-menu-item>
          <mat-icon>color_lens</mat-icon> 
          <!-- format_color_fill -->
          <span>Couleur</span>
        </button>
      </mat-menu>
      
    </mat-card-header>
  </a>
    <mat-divider></mat-divider>

    <mat-card-content>

        <div class="headline">
            <mat-icon class="icon-circle">solar_power</mat-icon>
            {{ data().participants }} Producteurs (capacité kWc)
        </div>

        <div class="headline">
            <mat-icon class="icon-circle">power</mat-icon>
            {{ data().nbConsommateurs }} Consommateurs
        </div>

        <div class="headline">
            <mat-icon class="icon-circle">layers</mat-icon>
            {{ data().scenarios }} Scénarios
        </div>

    </mat-card-content>

  </mat-card>
