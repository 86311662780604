<mat-toolbar>
    <button mat-icon-button class="home-icon">
      <mat-icon>{{iconPage}}</mat-icon>
    </button>
    <span>{{titrePage}}</span>
    <div class="end">
      <mat-form-field appearance="outline" class="button-rechercher">
        <mat-label>Rechercher</mat-label>
        <input matInput placeholder="Votre recherche ...">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field class="menu-trie" appearance="outline" >
        <mat-icon id="trie">filter_list</mat-icon>
          <mat-select id="option-select" [(ngModel)]="value" canSelectNullableOptions>
            @for (option of options; track option) {
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            }
          </mat-select>
      </mat-form-field>
      @if (afficheBoutonNouveauProjet) {
      <button mat-flat-button class="new-projet" (click)="OuvreDialogProjet()">
        <div class="icon">
            <mat-icon>add</mat-icon>
        </div>
        <div class="texte">Nouveau projet</div>
    </button>
    }
    </div>
</mat-toolbar>