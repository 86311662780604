import { Component } from '@angular/core';
import {ToolbarComponent} from '../../Composants/toolbar/toolbar.component';

@Component({
  selector: 'app-a-propos',
  imports: [ToolbarComponent],
  templateUrl: './a-propos.component.html',
  styleUrl: './a-propos.component.scss'
})
export class AProposComponent {

}
