import { Component, WritableSignal, linkedSignal, ChangeDetectorRef, signal, ChangeDetectionStrategy, effect, inject } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet, ListeScenarios } from '../../Services/EntiteProjet';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import { CardProjetComponent } from "../../Composants/card-projet/card-projet.component";
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ToolbarComponent} from "../../Composants/toolbar/toolbar.component";
import {CreationDialogComponent} from "../../Composants/creation-dialog/creation-dialog.component";

@Component({
  selector: 'app-projets',
  imports: [MatIconModule, MatCardModule, MatChipsModule, MatProgressBarModule, CardProjetComponent,MatToolbarModule, FormsModule, MatInputModule,MatSelectModule,MatFormFieldModule,ToolbarComponent,CreationDialogComponent],
  templateUrl: './projets.component.html',
  styleUrl: './projets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class ProjetsPage {
  value: number | null = null;
  options = [
    {label: 'Aucun', value: null},
    {label: 'De A à Z', value: 1},
    {label: 'Par date', value: 2},
    {label: 'Catégorie', value: 3},
    {label: 'Classement', value: 4},
  ];
trierProjets(arg0: any) {
throw new Error('Method not implemented.');
}
len(arg0: ListeScenarios) {
throw new Error('Method not implemented.');
}
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];
  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
  }
  
    ngOnInit(){
    
      this.ps.listeProjets().then(l => {
        if (l)
          this.ListeProjets = l;
        console.log('ProjetsPage::ngOnInit',this.ListeProjets);
        this.cd.markForCheck();
      });
    } 
}

