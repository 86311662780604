import { Component, WritableSignal, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet } from '../../Services/EntiteProjet';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AuthentificationService, AuthTokenInfo } from '../../Services/authentification.service';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-menus',
  imports: [MatIconModule, MatMenuModule],
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenusComponent implements OnInit {
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];
  authInfo!: AuthTokenInfo | null;

  constructor(
    private _parent: AppComponent,
    private cd: ChangeDetectorRef,
    protected ps: ProjetService,
    private router: Router,
    private auth: AuthentificationService
  ) {
    // Linking the parent signalProjetCourant
    this.signalProjetCourant = this._parent.signalProjetCourant;
    console.log("RouteCourant",this.router.url)
    this.authInfo = auth.tokenInfoSignal();
  }

  /**
   * Lifecycle hook for component initialization.
   */
  ngOnInit(): void {
    // Fetch the list of projects on initialization
    this.ps.listeProjets().then((projects) => {
      if (projects) {
        this.ListeProjets = projects;
        // Mark for change detection if necessary
        this.cd.markForCheck();
      }
    });
  }

  /**
   * Determines if the given URL is currently active.
   * @param url - The URL to check.
   * @returns true if the current route matches the given URL, false otherwise.
   */
  isActive(url: string): boolean {
    console.log(this.router.url,url)
    const exp = new RegExp(url);
    const res = this.router.url.match(exp);
    return (res && res.length> 0) ? true : false;
  }

  logout(){
    this.auth.logOut();
  }

}
