import { ChangeDetectionStrategy, Component, inject, Injectable, model } from '@angular/core';
import { Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-msg-alerte',
  imports: [ MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule ],
  templateUrl: './msg-alerte.component.html',
  styleUrl: './msg-alerte.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsgAlerteComponent {
  readonly data = inject(MAT_DIALOG_DATA);
  readonly titre = this.data.titre;
  readonly sujet = this.data.sujet;
  readonly msg = this.data.msg;
  readonly redirige = this.data.redirige;

  constructor(private router: Router) {}

  close() {
    if (this.redirige)
      this.router.navigateByUrl(this.redirige);
  }
}

