<app-toolbar page="Accueil">
    <app-creation-dialog></app-creation-dialog>
</app-toolbar>

<div class="section-title">Projets récents</div> 

<div class="projets-container">
    @for (p of ListeProjets.slice(0, 4); track p.id) {
    <app-card-projet [data]=p></app-card-projet>
    }
</div>
