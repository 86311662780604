import { Component, WritableSignal, ChangeDetectorRef, ChangeDetectionStrategy, input, OnInit} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet, ListeScenarios } from '../../Services/EntiteProjet';


@Component({
  selector: 'app-card-projet',
  imports: [MatIconModule,MatCardModule, MatChipsModule, MatProgressBarModule,MatMenuModule, CommonModule,MatDividerModule,MatTooltipModule],
  templateUrl: './card-projet.component.html',
  styleUrl: './card-projet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CardProjetComponent implements OnInit {

  public data = input.required<EntiteProjet>();

  trierProjets(arg0: any) {
    throw new Error('Method not implemented.');
  }
  len(arg0: ListeScenarios) {
    throw new Error('Method not implemented.');
  }
  truncate(text: string, maxLength: number): string {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];
  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
      this.signalProjetCourant = this._parent.signalProjetCourant;
  }
    
  ngOnInit(){
    
    this.ps.listeProjets().then(l => {
      if (l)
        this.ListeProjets = l;
      console.log('ProjetsPage::ngOnInit',this.ListeProjets);
      this.cd.markForCheck();
    });
    // const l = await this.ps.listeProjets();
    // if (l)
    //   this.ListeProjets = l;

  } 
}

