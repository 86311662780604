import { filter } from "rxjs";
import { Component, signal, linkedSignal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet,NavigationEnd } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { environment } from './Environments/environment';
import { EntiteProjet } from "./Services/EntiteProjet";
import { ProjetService } from "./Services/projet.service";
import { MenusComponent } from './Composants/menus/menus.component';
import { InfosComponent } from './Composants/infos/infos.component';
import { AuthentificationService } from "./Services/authentification.service";


@Component({
  selector: 'app-root',
  imports: [ CommonModule, RouterOutlet, MatSlideToggleModule, MenusComponent, InfosComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }

  protected version = signal('Version ' + environment.VERSION);
  protected showMenu = signal(false);
  protected showInfos = signal(false);
  protected darkMode = signal<boolean>(false);
  public signalProjetCourant: WritableSignal<EntiteProjet>;

  constructor(private router: Router, private ps: ProjetService, private auth: AuthentificationService) {
    // Récupération du mode d'affichage
    const storedDarkMode = localStorage.getItem('darkmode');
    if (storedDarkMode)
    {
      this.darkMode.set(JSON.parse(storedDarkMode));
      if (this.darkMode())
        document.body.classList.add('dark-mode');
      else
        document.body.classList.remove('dark-mode');
    }
    // Sélection des composants du template
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      //console.log(event);
      if (event.url == '/' || event.url.match('/accueil')) {
        this.showMenu.set(true);
        this.showInfos.set(true);
      } else if (event.url.match('/connexion')) {
        this.showMenu.set(false);
        this.showInfos.set(false);
      }
      else {
        this.showMenu.set(true);
        this.showInfos.set(false);
      }
    });
    // exporter l'ID du projet courant (si choisi ...)
    this.signalProjetCourant = linkedSignal({
      source: this.ps.projetCourant,
      computation: (pc) => {
        console.log('~AppComponent => ID de projet récupéré :', pc.id);
        return pc;
      }
    });
  }

  ngOnInit() {
    console.log('AppComponent::ngOnInit', this.router.url);
  }

  toggleMenu() {
    this.showMenu.set(! this.showMenu());
  }

  toggleInfos() {
    this.showInfos.set(! this.showInfos());
  }

  toggleDarkMode() {
    this.darkMode.set(! this.darkMode());
    localStorage.setItem('darkmode', JSON.stringify(this.darkMode()));
    if (this.darkMode())
      document.body.classList.add('dark-mode');
    else
      document.body.classList.remove('dark-mode');
  }

  majProjetCourant(pc: EntiteProjet) {
    this.signalProjetCourant.set(pc);
  }

  // isAdmin(): boolean {
  //   let res: boolean = false;
  //   this.auth?.roles.forEach( r => {
  //     if (r == 'ROLE_ADMIN')
  //       res = true;
  //   });
  //   return res;
  // }

}
