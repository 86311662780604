import { timer } from 'rxjs';
import { Component, WritableSignal, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, viewChild, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ToolbarComponent} from '../../Composants/toolbar/toolbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EntiteProjet } from "../../Services/EntiteProjet";
import { ProjetService } from '../../Services/projet.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-proj-producteurs',
  imports: [ ToolbarComponent,MatSidenavModule ],
  templateUrl: './proj-producteurs.component.html',
  styleUrl: './proj-producteurs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjProducteursPage {
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected projetCourant: EntiteProjet;
  // Gestion interface
  protected etatDetail = signal(true);

  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
    this.projetCourant = this.signalProjetCourant();
  }

  ngOnInit() {
    const idProjet = Number(this.activatedRoute.snapshot.paramMap.get('idProjet') as string);
    if (idProjet < 1) {
      this._parent.majProjetCourant(this.ps.nouveauProjet());
      this.router.navigateByUrl('/accueil');
      return;
    }
    console.log('ProjConsommateursPage::onInit => idProjet : ' + idProjet);
    if (this.projetCourant.id != idProjet) {
      this.ps.detailProjet(idProjet).then(pc => {
        console.log('ProjConsommateursPage::onInit => Projet récupéré =>', pc)
        if (pc) {
          this.projetCourant = pc;
          this._parent.majProjetCourant(pc);
          this.cd.markForCheck();
        }
      });
    }
  }

  afficheDetail(etat: boolean) {
    this.etatDetail.set(etat);
  }

  toggleDetail() {
    this.afficheDetail(! this.etatDetail());
  }

  async changeProjet() {
    const id = (this.projetCourant.id == 73) ? 83 : 73;
    this.ps.detailProjet(id).then(pc => {
      console.log('ProjConsommateursPage::onInit => Projet récupéré =>', pc)
      if (pc) {
        this.projetCourant = pc;
        this._parent.majProjetCourant(pc);
        this.cd.markForCheck();
      }
    });
  }

}
