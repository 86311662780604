import { Component } from '@angular/core';
import {ToolbarComponent} from '../../Composants/toolbar/toolbar.component';

@Component({
  selector: 'app-mon-compte',
  imports: [ToolbarComponent],
  templateUrl: './mon-compte.component.html',
  styleUrl: './mon-compte.component.scss',
})

export class MonComptePage {
}