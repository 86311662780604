<div class="main-container mat-drawer-content">
  @if (showMenu()) {
  <div id="left-content">
    <div id="info-version">{{ version() }}</div>
    <a href="accueil">
      <img src="/assets/logo-dark.png">
    </a>
    <div id="mode-button">
      <mat-slide-toggle [checked]="darkMode()" (change)="toggleDarkMode()">
        @if (darkMode()) { mode obscur } @else { mode clair }
      </mat-slide-toggle>
    </div>
    <app-menus></app-menus>
  </div>
  }
  <div id="center-content">
  <!-- <router-outlet [routerOutletData]="signalProjetCourant()"/> -->
    <router-outlet />
  </div>
  @if (showInfos()) {
  <div id="right-content">
    <app-infos></app-infos>
  </div>
  }
</div> 