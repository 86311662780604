<app-toolbar page="Projets">
  <app-creation-dialog>

  </app-creation-dialog>
</app-toolbar>

<div class="projets-container">
    @for (p of ListeProjets; track p.id) {
    <app-card-projet [data]=p></app-card-projet>
    }
</div>


