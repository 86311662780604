<app-toolbar page="Producteurs"></app-toolbar>

<header>
    <p class="left">ID projet: {{ projetCourant.id }}</p>
    <p class="right" >
        <button (click)="changeProjet()">Changer de projet</button>
    </p>
</header>
<mat-drawer-container class="container">
    <mat-drawer #detail class="right" position="end" mode="side" [opened]="etatDetail()">
        <p>sidenav détail</p>
    </mat-drawer>

    <div class="main">
        <button type="button" mat-button (click)="toggleDetail()">
            Toggle sidenav
        </button>
    </div>
</mat-drawer-container>
