<app-toolbar page="Scénarios"></app-toolbar>

<header>
    <p class="left">ID projet: {{ projetCourant.id }}</p>
    <p class="right" >
        <button (click)="changeProjet()">Changer de projet</button>
    </p>
</header>
<div class="main-container">
    <div id="left-content">
        Liste des producteurs
    </div>
    <div id="right-content">
        liste des consommateurs
    </div>
</div>