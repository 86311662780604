import { Component } from '@angular/core';
import {ToolbarComponent} from '../../Composants/toolbar/toolbar.component';


@Component({
  selector: 'app-turpe',
  imports: [ToolbarComponent,],
  templateUrl: './turpe.component.html',
  styleUrl: './turpe.component.scss'

})
export class TurpeComponent {
}