
<section class="navbar">
  <div class="logout">
    <div>
    @if (signalProjetCourant().id > 0) {
      <a href="/accueil">
          <button mat-flat-button class="custom-button button-home" style="padding: 16px !important;">
              <mat-icon>home</mat-icon>
          </button>
      </a>
    }
    </div>
    <button mat-flat-button class="custom-button button-logout" [matMenuTriggerFor]="menu">
      {{authInfo?.prenom}} 
      <!-- {{authInfo?.nom?.slice(0, 10)}} -->
      <mat-icon>account_circle</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <a href="/mon-compte">
      <button mat-menu-item >
        <mat-icon>edit</mat-icon>
        <span>Mon compte</span>
      </button>
    </a>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Se déconnecter</span>
    </button>
  </mat-menu>

@if (signalProjetCourant().id > 0) {
    <!-- <p>Projet courant : {{ signalProjetCourant().id }}</p> -->
        <div class="titre">{{ signalProjetCourant().libelle }}</div>
          <div class="button-nav">
            <a href="projet/{{ signalProjetCourant().id }}/producteurs">
              <button mat-flat-button class="custom-button" [class.active-button]="isActive('projet/.*/producteurs')">
                <mat-icon>solar_power</mat-icon>
                Producteurs
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="projet/{{ signalProjetCourant().id }}/consommateurs">
              <button mat-flat-button class="custom-button" [class.active-button]="isActive('/consommateurs')">
                <mat-icon>bolt</mat-icon>
                Consommateurs
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="projet/{{ signalProjetCourant().id }}/configuration">
              <button mat-flat-button class="custom-button" [class.active-button]="isActive('/configuration')">
                <mat-icon>description</mat-icon>
                Configuration
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="projet/{{ signalProjetCourant().id }}/scenarios">
              <button mat-flat-button class="custom-button" [class.active-button]="isActive('/scenarios')"> 
                <mat-icon>layers</mat-icon>
                Scénarios
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="projet/{{ signalProjetCourant().id }}/bilan">
              <button mat-flat-button class="custom-button" [class.active-button]="isActive('/bilan')">
                <mat-icon>assignment_turned_in</mat-icon>
                Bilan
              </button>
            </a>
          </div>
        <span layout="row"><hr flex/></span>

        <section>
            <div class="titre">Projets Ouverts</div>
            <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button" [class.active-button]="isActive('/TODO----------------')"> 
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 1
                  </button>
                </a>
              </div>
              <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button" [class.active-button]="isActive('/TODO----------------')">
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 2
                  </button>
                </a>
              </div>
              <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button" [class.active-button]="isActive('/TODO----------------')">
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 3
                  </button>
                </a>
              </div>
        </section>
} @else {
    <!-- <div class="titre">Navigation</div> -->
    <div class="button-nav">
      <a href="/accueil">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/accueil')">
              <mat-icon>home</mat-icon>
              Page d'accueil
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/projets">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/projets')">
              <mat-icon>folder</mat-icon>
              Mes projets
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/turpes">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/turpe')">
              <mat-icon>description</mat-icon>
              TURPE et Tarif d'achat
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/base-donnees">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/base-donnees')">
              <mat-icon>bar_chart</mat-icon>
              Base de données
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/mon-compte">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/mon-compte')">
              <mat-icon>account_circle</mat-icon>
              Mon compte
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/docs-tutos">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/docs-tutos')">
              <mat-icon>help_outline</mat-icon>
              Docs et Tutos
          </button>
      </a>
    </div>
    <div class="button-nav">
      <a href="/a-propos">
          <button mat-flat-button class="custom-button" [class.active-button]="isActive('/a-propos')">
              <mat-icon>info</mat-icon>
              À propos
          </button>
      </a>
    </div>

}
</section>
