import { Component, WritableSignal, ChangeDetectorRef, inject, input } from '@angular/core';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet, ListeScenarios } from '../../Services/EntiteProjet';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CreationDialogComponent} from '../creation-dialog/creation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-toolbar',
  imports: [MatIconModule, MatCardModule, MatChipsModule, MatProgressBarModule,MatToolbarModule, FormsModule, MatInputModule,MatSelectModule,MatFormFieldModule],  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  OuvreDialogProjet(){
    const dialogRef = this.dialog.open(CreationDialogComponent, {
  });
  }
  readonly dialog = inject(MatDialog);
  public page = input<string>();
  public titrePage : string='';
  public iconPage : string='';
  public afficheBoutonNouveauProjet: boolean = false;

  value: number | null = null;
  options = [
    {label: 'Aucun', value: null},
    {label: 'De A à Z', value: 1},
    {label: 'Par date', value: 2},
    {label: 'Catégorie', value: 3},
    {label: 'Classement', value: 4},
  ];
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];

  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
  }
  
  ngOnInit(){
    //console.log('page courante',this.page());
    switch(this.page()){
      case 'Accueil':
        this.titrePage='Accueil';
        this.iconPage='home';
      break;
      case 'Projets':
        this.titrePage='Mes projets';
        this.iconPage='folder';
        this.afficheBoutonNouveauProjet = true;
      break;
      case 'TURPE':
        this.titrePage='TURPE et Tarif d\'achat';
        this.iconPage='description';
      break;
      case 'Base de données':
        this.titrePage='Base de données';
        this.iconPage='bar_chart';
      break;
      case 'Mon compte':
        this.titrePage='Mon compte';
        this.iconPage='person';
      break;
      case 'Docs et Tutos':
        this.titrePage='Docs et Tutos';
        this.iconPage='help';
      break;
      case 'À propos':
        this.titrePage='À propos';
        this.iconPage='info';
      break;
      case 'Producteurs':
        this.titrePage='Producteurs';
        this.iconPage='solar_power';
      break;
      case 'Consommateurs':
        this.titrePage='Consommateurs';
        this.iconPage='bolt';
      break;
      case 'Configuration':
        this.titrePage='Configuration';
        this.iconPage='description';
      break;
      case 'Scénarios':
        this.titrePage='Scénarios';
        this.iconPage='layers';
      break;
      case 'Bilan':
        this.titrePage='Bilan';
        this.iconPage='assignment_turned_in';
      break;
    }
  } 

  ngAfterViewInit() {
    // Stylisation pour le bouton "Menu Trier"
    const menuTrieElement = document.querySelector(
      "div.end > mat-form-field > div.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--no-label > div > div.mat-mdc-form-field-infix"
    );
    if (menuTrieElement) {
      (menuTrieElement as HTMLElement).style.paddingTop = '0px';
      (menuTrieElement as HTMLElement).style.paddingBottom = '0px';
      (menuTrieElement as HTMLElement).style.minHeight = '0px';
      (menuTrieElement as HTMLElement).style.width = '110px';
      (menuTrieElement as HTMLElement).style.display = 'flex';
      (menuTrieElement as HTMLElement).style.marginTop = '10px';
    }

    // Stylisation pour le champ "Rechercher"
    const rechercherConteneur = document.querySelector(
      "div.end > mat-form-field.button-rechercher > div.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined > div > div.mat-mdc-form-field-infix"
    );
    if (rechercherConteneur) {
      (rechercherConteneur as HTMLElement).style.paddingTop = '0px';
      (rechercherConteneur as HTMLElement).style.paddingBottom = '0px';
      (rechercherConteneur as HTMLElement).style.minHeight = '0px';
      (rechercherConteneur as HTMLElement).style.width = '110px';
      (rechercherConteneur as HTMLElement).style.display = 'flex';
      (rechercherConteneur as HTMLElement).style.marginTop = '10px';
    }
  }
}