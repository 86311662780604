import { Component } from '@angular/core';
import {ToolbarComponent} from '../../Composants/toolbar/toolbar.component'

@Component({
  selector: 'app-docs-tutos',
  imports: [ToolbarComponent],
  templateUrl: './docs-tutos.component.html',
  styleUrl: './docs-tutos.component.scss'
})
export class DocsTutosComponent {

}
